// @ts-nocheck
export function Logo() {
  return (
    <svg
      id="a"
      style={{ color: '#262352' }}
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="167.35"
      height="167.35"
      viewBox="0 0 167.35 167.35"
    >
      <path d="M83.67,0C37.46,0,0,37.46,0,83.67s37.46,83.67,83.67,83.67,83.67-37.46,83.67-83.67S129.88,0,83.67,0Zm5.52,136.8c-1.38,2.03-4.71,5.04-6.8,6.36-6.82,4.3-14.16,4.42-21.42,1.29-6.75-2.92-10.52-8.27-10.9-15.71-.41-7.89,2.27-14.83,6.89-21.11,.96-1.31,2.01-2.6,2.72-4.05,1.5-3.06,.1-6.22-3.19-6.92-2.26-.48-4.71-.81-6.96-.45-8.86,1.4-15.05,6.55-18.68,14.63-2.15,4.78-2.32,9.79-1.78,14.83-8.66-11.46-13.8-25.73-13.8-41.2,0-36.36,28.36-66.09,64.17-68.27-4.48,3.65-7.62,8.4-10.34,13.42-7.75,14.3-8.52,28.96-1.9,43.89,2.79,6.3,6.7,11.91,10.72,17.46,5.18,7.15,9.53,14.76,12.59,23.06,3.09,8.4,3.78,15.25-1.33,22.77Zm9.71,14.36c2.56-2.5,4.74-5.53,6.56-9.07,4.06-7.93,4.42-16.25,2.18-24.75-3.07-11.65-8.17-22.46-14.02-32.9-4.94-8.82-9.06-17.82-10.08-28.01-.9-9.04-.13-16.78,4.21-24.62,2.4-4.33,7.83-7.02,12.81-6.86,6.51,.21,11.59,5.03,12.51,11.75,.49,3.6,.06,7.13-.98,10.6-1.55,5.14-4.06,9.78-7.04,14.22-1.59,2.37-3.08,4.74-3.09,7.84-.02,6.67,3.42,11.62,7.74,16.12,1.54,1.61,3.67,1.46,5.37,.28,1.74-1.2,3.39-2.66,4.71-4.3,5.43-6.79,8.89-14.65,11.81-22.74,2.39-6.63,3.51-13.31,2.8-20.16,10.99,12.14,17.69,28.24,17.69,45.9,0,32.54-22.73,59.78-53.18,66.7Z" />
    </svg>
  );
}
